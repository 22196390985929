//React
import React from 'react';

import { connect } from 'react-redux';

//Reselect
import { createStructuredSelector } from 'reselect';
//Selectors
import { selectSteps, selectStepNow } from '../../redux/steps/steps.selectors';



//Components
import Step from './step/step';

//Style
import { StepsPanelStyle } from './steps.style';

class Steps extends React.Component {

    render() {
        const { steps_value, stepNow } = this.props;

        return (
            <StepsPanelStyle>
                {
                    steps_value.map(step => {
                        return (<Step key={step.step} mode={step.mode} name={step.name} step={step.step} stepNow={stepNow}></Step>)
                    })
                }
            </StepsPanelStyle>
        )
    }
};


const mapStateToProps = createStructuredSelector({
    steps_value: selectSteps,
    stepNow: selectStepNow
});

export default connect(mapStateToProps)(Steps);