import React from 'react';

//Icons
import { ReactComponent as CheckSvg } from '../../../../assets/icons/check.svg';

//Style
import {
    CheckBoxEnable,
    CheckBoxDisable
} from './check.box.style';

export const CheckBox = ({ checked, clickCheckBox }) => {

    return (
        <React.Fragment>
            {
                checked === true ?
                    <CheckBoxEnable onClick={() => clickCheckBox()}>
                        <CheckSvg width='115%' />
                    </CheckBoxEnable>
                    :
                    <CheckBoxDisable onClick={() => clickCheckBox()} />
            }
        </React.Fragment>
    )
};