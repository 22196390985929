import React from 'react';
//React-redux
import { connect } from 'react-redux';
//Reselect
import { createStructuredSelector } from 'reselect';
//Actions
import { changeEvaradioCheckBox, changeNomikesCheckBox } from '../../redux/extraChoise/extra.choise.actions';
import { changeValueEvaradio, changeValueNomiki, changeValueSum } from '../../redux/result/result.actions';
//Selectors
import { selectExtraChoise } from '../../redux/extraChoise/extra.choise.selectors';


//Components
import ExtraRow from './extraRow/extraRow.component';

//Styles
import {
    CheckExtraPanel,
    CheckExtraTitle
} from './check.extra.style';

class CheckExtra extends React.Component {

    //Prepei na allazei to state toy check box gia to evaradio
    clickCheckBoxEvaradio = () => {
        const { changeEvaradioCheckBox, selectExtraChoise, changeValueEvaradio, changeValueSum } = this.props;
        const newValue = !selectExtraChoise.evaradio.checked;
        changeEvaradioCheckBox(newValue);

        if (newValue) {
            changeValueEvaradio(20);
            changeValueSum();
        } else {
            changeValueEvaradio(0);
            changeValueSum();
        }
    };

    //Prepei na allazei to state toy check box gia to nomikes
    clickCheckBoxNomikes = () => {
        const { changeNomikesCheckBox, selectExtraChoise, changeValueNomiki, changeValueSum } = this.props;
        const newValue = !selectExtraChoise.nomikes.checked;
        changeNomikesCheckBox(newValue);
        if (newValue) {
            changeValueNomiki(20);
            changeValueSum();
        } else {
            changeValueNomiki(0);
            changeValueSum();
        }
    };

    render() {
        const { selectExtraChoise } = this.props;

        return (
            <CheckExtraPanel>

                <CheckExtraTitle>Επιπλέον Παροχές</CheckExtraTitle>

                <div>
                    <ExtraRow title="Υπηρεσία evaradio.gr ( +20€ Έτος )" checked={selectExtraChoise.evaradio.checked} clickCheckBox={this.clickCheckBoxEvaradio} link='https://www.emusic.gr/evaradio' />
                    <ExtraRow title="Νομικές Συμβουλές ( +20€ Έτος )" checked={selectExtraChoise.nomikes.checked} clickCheckBox={this.clickCheckBoxNomikes} link='https://www.emusic.gr/nomikes' />
                </div>
            </CheckExtraPanel>
        )
    }
};

const mapStateToProps = createStructuredSelector({
    selectExtraChoise: selectExtraChoise
});

const mapDispatchToProps = dispatch => ({
    changeEvaradioCheckBox: (value) => dispatch(changeEvaradioCheckBox(value)),
    changeNomikesCheckBox: (value) => dispatch(changeNomikesCheckBox(value)),

    changeValueEvaradio: (value) => dispatch(changeValueEvaradio(value)),
    changeValueNomiki: (value) => dispatch(changeValueNomiki(value)),
    changeValueSum: () => dispatch(changeValueSum()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckExtra);