import styled from 'styled-components';

import { color_Grey_Medium } from '../../styles/colors'

export const PanelSTepStyle = styled.div`
    width:110.04rem;
    height: 44.9rem;
    position: relative;

    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;

    border: 1px solid ${color_Grey_Medium};

    opacity: 1;

    @media only screen and (max-width:900px){
        min-height: 60rem;
        height: auto;
        width: 80%;
        padding-bottom:10rem;
    }

    @media only screen and (max-width:800px){
        width: 90%;
    }
    @media only screen and (max-width:700px){
        width: 98%;
    }
`;