import styled from 'styled-components';

//Colors
import { color_Grey_Medium, color_Grey_Dark } from '../../styles/colors';

export const TopLabelStyle = styled.h2`
    margin:1.5rem auto;
    font-size: 3.5rem;
    font-weight: 500;
    text-align: center;
    
    color:${color_Grey_Dark};

    letter-spacing: 1.5px;
    /* word-spacing: 1.5px; */
    line-height: 1.2;
    ${({ step }) => step === 1 ?
        { width: '45%' } :
        step === 2 ?
            { width: '45%' } :
            { width: '80%' }};
    
    @media only screen and (max-width:960px){
        font-size:3rem;
    }
            
    @media only screen and (max-width:900px){
        margin:1.5rem auto 3rem;
        min-width: 50%;
        font-weight:600;
        width: 90%;
        line-height: 1.5;
        font-size: 2.8rem;
    }

    @media only screen and (max-width:550px){
        font-size: 2.4rem;
    }
    @media only screen and (max-width:450px){
        font-size: 2.2rem;
    }
`;