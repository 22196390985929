import React from 'react';

//Style
import { BtnPanelStyle } from './btn.pdf.style';

export const BtnPDF = ({ enable, onClickBtnPDF }) => {
    return (
        <BtnPanelStyle enable={enable} onClick={() => onClickBtnPDF()}><span>PDF</span></BtnPanelStyle>
    )
};

