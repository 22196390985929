import { selectionsType } from './selection.type';

//Utilities
import {
    saveSelectCategori,
    saveSelectRooms,
    saveSelectTropo,
    saveSelectXoros,
    saveSelectPeriodos,
    saveSelectKinoxristi,
    saveSelectEkdilosis,

    changeNextSelectorShow,
    changeShowSelections,

    generateRandomNumber,
} from './selections.utilities';

//Data
import { INITIAL } from './selection.data';


const selectionsReducer = (state = INITIAL, action) => {
    switch (action.type) {
        case selectionsType.CHANGE_SELECTIONS_SHOW:
            return changeShowSelections(state, action.payload);

        case selectionsType.SAVE_SELECT_CATEGORI:
            return saveSelectCategori(state, action.payload);

        case selectionsType.SAVE_SELECT_ROOMS:
            return saveSelectRooms(state, action.payload);

        case selectionsType.SAVE_SELECT_TROPO:
            return saveSelectTropo(state, action.payload);

        case selectionsType.CHANGE_SELECTOR_SHOW:
            return changeNextSelectorShow(state, action.payload, action.value);

        case selectionsType.SAVE_SELECT_XOROS:
            return saveSelectXoros(state, action.payload);

        case selectionsType.SAVE_SELECT_PERIODOS:
            return saveSelectPeriodos(state, action.payload);

        case selectionsType.SAVE_SELECT_KINOXRISTI:
            return saveSelectKinoxristi(state, action.payload);

        case selectionsType.SAVE_SELECT_EKDILOSIS:
            return saveSelectEkdilosis(state, action.payload);

        case selectionsType.GENARATE_RANDOM_NUMBER:
            return generateRandomNumber(state);

        case selectionsType.CLEAR_SELECTED_ITEMS:
            return {
                ...state, selected: {
                    categori: {},
                    rooms: {},
                    tropos: {},
                    xoros: {},
                    periodos: {},
                    kinoxristi: {},
                    ekdilosis: {},
                    randomNumber: '',
                }
            }

        default:
            return state;
    }
};

export default selectionsReducer;