import styled from 'styled-components';

export const PanelSelectStyle = styled.div`
    height: 30rem;
    padding:2rem 0 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: left;

    @media only screen and (max-width:650px){
        padding: 2rem;
        height: auto;
    }

    @media only screen and (max-width:450px){
        padding: .5rem;
    }
`;