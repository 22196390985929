import styled from 'styled-components';

import {
    color_Green_Ligth,
    color_Green_Medium,
    color_Green_Dark,
    color_Grey_Medium,
    color_White
} from '../../../styles/colors';


export const ButtonChangeStyle = styled.div`
    width:24.3rem;
    height: 5.7rem;

    position: absolute;

    ${({ side }) => side === "rigth" ?
        {
            right: '2rem',
            bottom: '2rem'
        }
        :
        {
            left: '2rem',
            bottom: '2rem'
        }
    }

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${color_Green_Medium};

    border-radius:.5rem;
    border: 1px solid ${color_Grey_Medium};

    cursor: pointer;
    
    user-select: none;

    &:hover{

        background-color: ${color_Green_Ligth};
    }

    &:active{

        background-color: ${color_Green_Dark};
    }

    @media only screen and (max-width:550px){
        width: auto;
        min-width:20rem;
        height: auto;
        padding: .7rem 3rem;
    }
    
    @media only screen and (max-width:500px){
        padding: .7rem 1.5rem;
    }
    @media only screen and (max-width:400px){
        min-width: 18rem;
        padding: .7rem 1rem;
    }
    @media only screen and (max-width:350px){
        min-width: 14rem;
        padding: .7rem 1rem;
    }
    @media only screen and (max-width:300px){
        min-width: 12rem;
        padding: .7rem 1rem;
    }
`;

export const ButtonTitleStyle = styled.h2`
    color:${color_White};
    font-size:3rem;
    font-weight: 400;
    letter-spacing: 1.5px;
    text-align: center;

    @media only screen and (max-width:900px){
        font-size: 2.8rem;
    }
    @media only screen and (max-width:550px){
        font-size: 2.6rem;
        letter-spacing: 1px;
    }
    @media only screen and (max-width:500px){
        font-size: 2.2rem;
        letter-spacing: 1px;
    }
    @media only screen and (max-width:400px){
        font-size: 2rem;
        letter-spacing: 1px;
    }
    @media only screen and (max-width:350px){
        font-size: 1.8rem;
        letter-spacing: 0;
    }
    @media only screen and (max-width:300px){
        font-size: 1.6rem;
    }

`;