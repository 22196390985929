import { stepstypes } from './steps.types';

import {
    changeStepNext,
    changeStepPreviews
} from './steps.utilities';

const INITIAL = {
    now: 1,
    nextBtn: false,
    previewsBtn: false,
    steps: [
        { step: 1, mode: 0, name: 'Επιλογές' },
        { step: 2, mode: 0, name: 'Τιμολόγηση' },
        { step: 3, mode: 0, name: 'Οδηγίες' },
    ]
}

const stepsReducer = (state = INITIAL, action) => {
    switch (action.type) {
        case stepstypes.STEP_NEXT:
            return { ...state, now: changeStepNext(state.now) };;

        case stepstypes.STEP_PREVIEW:
            return { ...state, now: changeStepPreviews(state.now) };

        case stepstypes.OPEN_NEXT_BTN:
            return { ...state, nextBtn: true };

        case stepstypes.CLOSE_NEXT_BTN:
            return { ...state, nextBtn: false };

        default:
            return state;
    }
}

export default stepsReducer;