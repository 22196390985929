import styled from 'styled-components';

export const StepsPanelStyle = styled.div`
    width:65%;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 2rem;

    @media only screen and (max-width:700px){
        width: 90%;
    }
`;