import { createSelector } from 'reselect';


const selectSelections = (state) => state.selections;

//Selectors
export const selectSelectors = createSelector(
    [selectSelections],
    selections => selections.selectors.length > 0 ? selections.selectors : null
);

//Categories
export const selectCategories = createSelector(
    [selectSelections],
    selections => selections.catogories
);

//Tropos
export const selectTropos = createSelector(
    [selectSelections],
    selections => selections.tropos
);

//[Xoros]

//Periodos
export const selectPeriodos = createSelector(
    [selectSelections],
    selections => selections.periodos
);

//Kinoxristi
export const selectKinoxristi = createSelector(
    [selectSelections],
    selections => selections.kinoxristi
);

//Ekdilosis
export const selectEkdilosis = createSelector(
    [selectSelections],
    selections => selections.ekdilosis
);

//Selected Items
export const selectedItems = createSelector(
    [selectSelections],
    selections => selections.selected
)