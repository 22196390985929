import styled, { css } from 'styled-components';

//Colors
import { color_Grey_Medium, color_Grey_Dark, corol_label_green, color_Green_Medium, color_White, color_Green_Dark } from '../../styles/colors';

const defaultPosition = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
`
export const LabelStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items:center;
    text-align: center;
    &:not(:last-child){
        margin-bottom: 1rem;
    }
`;

export const LabelCostPanel = styled.div`
    ${defaultPosition}
    border-radius: 5px;
    border:1px solid;
    ${({ mode }) => mode === 'green' ?
        { borderColor: color_Grey_Dark, backgroundColor: corol_label_green, color: color_Grey_Dark, minWidth: '25rem' }
        :
        { borderColor: color_Grey_Medium, backgroundColor: color_White, color: color_Grey_Medium, minWidth: '19rem' }
    }
    min-height: 5rem;
    margin-left:2rem;
    @media only screen and (max-width:600px){
        min-height: 4rem;
        min-width: 14rem;
    }
    @media only screen and (max-width:350px){
        min-height: 3.5rem;
        min-width: 10rem;
    }
`;

export const LabelCostStyle = styled.h3`
    font-size: 3rem;
    font-weight: 400;
    @media only screen and (max-width:960px){
        font-size: 2.6rem;
    }
    @media only screen and (max-width:600px){
        font-size: 2.2rem;
    }
    @media only screen and (max-width:450px){
        font-size: 2rem;
    }
    @media only screen and (max-width:350px){
        font-size: 1.8rem;
    }

`;

export const LabelTitle = styled.span`
    font-size: 3rem;
    font-weight: 400;
    ${({ mode }) => mode === 'green' ?
        { color: color_Green_Medium }
        :
        { color: color_Grey_Medium }
    }
    line-height: 1;

    @media only screen and (max-width:960px){
        font-size: 2.6rem;
    }
    @media only screen and (max-width:600px){
        font-size: 2.2rem;
    }
    @media only screen and (max-width:450px){
        font-size: 2rem;
    }
    @media only screen and (max-width:350px){
        font-size: 1.8rem;
    }
`;
