import React from 'react';

//React-Redux
import { connect } from 'react-redux';

//Actions
import {
    changeSelectionShow,
    changeSelectorShow,

    saveSelectCategori,
    saveSelectRooms,
    saveSelectTropo,
    saveSelectXoros,
    saveSelectPeriodos,
    saveSelectKinoxristi,
    saveSelectEkdilosis,

    clearSelectedItems,
    generateRandomNumber
} from '../../../redux/selections/selection.action';
import { openNextBtn, closeNextBtn } from '../../../redux/steps/steps.actions';

//Reselect
import { createStructuredSelector } from 'reselect';

//Selectors
import { selectSelectors, selectCategories, selectTropos, selectPeriodos, selectKinoxristi, selectEkdilosis, selectedItems } from '../../../redux/selections/selections.selectors';

//Components
import BtnDropBox from '../../buttons/btn_dropbox/btn.dropbox.component';

//Extra Functions Utilities
import { findSelectionForTropos, setXorosTMSelections } from './select.panel.utilities';

import { findCostForSelections } from './select.panel.cost.utilities';

//Style
import { PanelSelectStyle } from './select.panel.style';

class SelectPanel extends React.Component {
    state = {
        showRoomSelection: false
    };

    selectionArrayTropos = [];
    selectionArrayXoros = [];

    selectionRooms = [];


    //Show or Hide the selections for selector
    clickForSelector = (value) => {
        //(value) must be the ID from selector
        // const newValue = { ...value }
        this.props.changeSelectionShow(value);
    }


    clickForSelectChoise = (selectorID, choise) => {
        const { saveSelectCategori, saveSelectTropo, saveSelectXoros, saveSelectPeriodos, changeNextSelectorShow, saveSelectEkdilosis, openNextBtn, closeNextBtn,
            selectedItems, saveSelectKinoxristi, clearSelectedItems, generateRandomNumber } = this.props;

        //Analoga me thn choise prepei na anoigei kai to epomeno btn epilogon
        switch (selectorID) {
            case 0:
                //Clear selected Items
                clearSelectedItems();
                //Close next step;
                closeNextBtn();

                const newChoiseCategori = { ...choise }

                if (choise.id < 5) {

                    //Oi epiloges poy exei o sta8eros tropos gia thn epilegmenh catigoria
                    this.selectionArrayTropos = [...findSelectionForTropos(this.props.tropos, choise.id)]

                    newChoiseCategori.data = this.selectionArrayTropos;

                    //Set Next Selector
                    changeNextSelectorShow(1);
                    changeNextSelectorShow(2, false);
                    changeNextSelectorShow(3, false);
                    changeNextSelectorShow(4, false);
                    changeNextSelectorShow(5, false);


                } else if (choise.id >= 5 && choise.id <= 7) {
                    changeNextSelectorShow(1, false);
                    changeNextSelectorShow(2, false);
                    changeNextSelectorShow(5, false);
                }

                //Categori Save on Redux
                saveSelectCategori(newChoiseCategori);

                break;

            case 1:
                const newChoiseXoros = { ...choise };


                //oi epiloges poy mporei na exei o xoros gia ton epilegmeno sta8ero tropo
                if (choise.id === 0 || choise.id === 3) {
                    if (selectedItems.categori.id === 2) {
                        this.selectionArrayXoros = [...setXorosTMSelections(0, 100, 50)];

                    } else {
                        this.selectionArrayXoros = [...setXorosTMSelections(0, 250, 50)];

                    }

                } else if (choise.id === 1 || choise.id === 2) {
                    this.selectionArrayXoros = [...setXorosTMSelections(0, 250, 30)];
                }

                newChoiseXoros.data = this.selectionArrayXoros;

                //Tropos Save on Redux
                saveSelectTropo(newChoiseXoros)

                //Set Next Selector
                changeNextSelectorShow(2);
                break;

            case 2:
                //Xoros TM Pelaton Save on Redux
                saveSelectXoros(choise);

                //Set Next Selector
                changeNextSelectorShow(5);
                break;

            case 3:
                //Kinoxristi xoroi Save on Redux
                saveSelectKinoxristi(choise);

                //Set Next Selector
                changeNextSelectorShow(4)
                break;

            case 4:
                //Ekdilosis Save on Redux
                saveSelectEkdilosis(choise);

                //Set Next Selector
                changeNextSelectorShow(5);
                break;


            case 5:
                //Periodos leitoyrgias Save on Redux
                saveSelectPeriodos(choise);

                //Prepei na kanei toys ypologismoys ton epilogon 
                findCostForSelections(selectedItems, choise);

                //Create 16 digits Random Number
                generateRandomNumber()

                //Prepei na anoigei to btn epomeno
                openNextBtn()
                break;

            default:
                break;
        }

        //na kleinei tis epiloges
        this.clickForSelector(selectorID);
    }


    //Click mono gia ta rooms
    clickForSelectorRooms = () => {
        this.setState({ showRoomSelection: !this.state.showRoomSelection });
    };


    clickForSelectionRooms = (rooms) => {
        const { changeNextSelectorShow, saveSelectRooms } = this.props;

        //Save on Redux
        saveSelectRooms(rooms);
        //Open Next Selector
        changeNextSelectorShow(3);
    }

    render() {
        const { selectors, categories, tropos, periodos, kinoxristi, ekdilosis, selectedItems } = this.props;

        return (
            <PanelSelectStyle>
                {
                    selectors !== null && selectors.map((selector, idx) => {
                        let choise = [];
                        let name = "";
                        let showRooms = false

                        //Prepei na kanei toys elegxoys gia na brei poio prepei na einai to epomeno btn pou 8a emfanisei
                        if (idx === 0) {
                            //Erxete an einai epilegmenes oi Catigories
                            choise = categories;
                            name = selectedItems.categori.name ? selectedItems.categori.name : selector.btnTitle;

                            if (selectedItems.categori.id === 5 || selectedItems.categori.id === 6 || selectedItems.categori.id === 7) {

                                showRooms = true;
                                let newItem = {};
                                this.selectionRooms = [];

                                for (let i = 0; i < 200; i++) {
                                    newItem.id = i;
                                    newItem.name = i + 1;
                                    this.selectionRooms.push({ ...newItem });
                                };

                            };


                        } else if (idx === 1) {
                            //Erxete an einai epilegmenos o sta8eros tropos
                            choise = this.selectionArrayTropos;
                            name = selectedItems.tropos.name ? selectedItems.tropos.name : selector.btnTitle;

                        } else if (idx === 2) {
                            //Erxete an einai epilegmenos o xoros pelaton
                            choise = this.selectionArrayXoros;
                            name = selectedItems.xoros.name ? selectedItems.xoros.name : selector.btnTitle;

                        } else if (idx === 3) {
                            choise = kinoxristi;
                            name = selectedItems.kinoxristi.name ? selectedItems.kinoxristi.name : selector.btnTitle;

                        } else if (idx === 4) {
                            choise = ekdilosis;
                            name = selectedItems.ekdilosis.name ? selectedItems.ekdilosis.name : selector.btnTitle;

                        } else if (idx === 5) {
                            //Erxete an einai epilegmenh h diarkeia
                            choise = [...periodos];
                            name = selectedItems.periodos.name ? selectedItems.periodos.name : selector.btnTitle;
                        }

                        return (
                            <BtnDropBox key={selector.id}
                                btnNum={selector.btnNum}
                                btnTitle={name}
                                show={selector.show}
                                selections={choise}
                                showSelections={selector.showSelections}

                                clickForSelector={this.clickForSelector}
                                clickForSelectChoise={this.clickForSelectChoise}

                                roomsTitle={selectedItems.rooms.name ? `Δωμάτια(${selectedItems.rooms.name})` : 'Δωμάτια'}
                                showRooms={showRooms}
                                showRoomSelections={this.state.showRoomSelection}
                                roomSelection={this.selectionRooms}
                                clickForSelectorRooms={this.clickForSelectorRooms}
                                clickForSelectionRooms={this.clickForSelectionRooms}

                                id={selector.id}
                            />
                        )
                    })
                }
            </PanelSelectStyle>
        )
    };
}

const mapStateToProps = createStructuredSelector({
    selectors: selectSelectors,
    categories: selectCategories,
    tropos: selectTropos,
    periodos: selectPeriodos,
    kinoxristi: selectKinoxristi,
    ekdilosis: selectEkdilosis,

    selectedItems: selectedItems
});


const mapDispatchToProps = dispatch => ({
    saveSelectCategori: (categori) => dispatch(saveSelectCategori(categori)),
    saveSelectRooms: (rooms) => dispatch(saveSelectRooms(rooms)),
    saveSelectTropo: (tropos) => dispatch(saveSelectTropo(tropos)),
    saveSelectXoros: (xoros) => dispatch(saveSelectXoros(xoros)),
    saveSelectPeriodos: (periodos) => dispatch(saveSelectPeriodos(periodos)),
    saveSelectKinoxristi: (kinoxristi) => dispatch(saveSelectKinoxristi(kinoxristi)),
    saveSelectEkdilosis: (ekdilosi) => dispatch(saveSelectEkdilosis(ekdilosi)),

    generateRandomNumber: () => dispatch(generateRandomNumber()),

    changeSelectionShow: (value) => dispatch(changeSelectionShow(value)),
    changeNextSelectorShow: (selectorID, value = true) => dispatch(changeSelectorShow(selectorID, value)),

    clearSelectedItems: () => dispatch(clearSelectedItems()),

    openNextBtn: () => dispatch(openNextBtn()),
    closeNextBtn: () => dispatch(closeNextBtn()),
});


export default connect(mapStateToProps, mapDispatchToProps)(SelectPanel);