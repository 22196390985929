import React from 'react';

//components
import { LabelCost } from '../labelCost/label.cost.component';

const StandarCost = ({ priceEdem = 0, priceGea = 0, priceAthina = 0, isHotel = false }) => {
    return (
        <React.Fragment>
            <LabelCost description='Κόστος Άδειας "ΕΔΕΜ"' price={priceEdem} />
            <LabelCost description={isHotel ? 'Κόστος Άδειας "GEA - Διόνυσος"' : 'Κόστος Άδειας "GEA"'} price={priceGea} />
            {isHotel && <LabelCost description='Κόστος Άδειας "Αθηνά"' price={priceAthina} />}
        </React.Fragment>
    )
};

export default StandarCost;