import React from 'react';

//Style
import { LabelStyle, LabelTitle, LabelCostPanel, LabelCostStyle } from './label.cost.style';

export const LabelCost = ({ description, price, mode }) => {

    return (
        <LabelStyle>
            <LabelTitle mode={mode}>{description}</LabelTitle>
            <LabelCostPanel mode={mode}>
                <LabelCostStyle>{price} €</LabelCostStyle>
            </LabelCostPanel>
        </LabelStyle>
    )
};