import styled from 'styled-components';

import { color_Blue_Ligth, color_Blue_Medium, color_Blue_Dark, color_White } from '../../../../styles/colors';

export const InfoExtraStyle = styled.a`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    border:3px solid ${color_Blue_Medium};
    user-select: none;
    cursor: pointer;
    margin-left: 2rem;
    background-color: ${color_White};

    color: ${color_Blue_Medium};

    &:hover{
        background-color: ${color_Blue_Medium};
        color:${color_White}
    }

    &:active{
        background-color: ${color_Blue_Dark};
        color:${color_White};
        border:3px solid ${color_Blue_Dark};
    }

    @media only screen and (max-width:400px){
        width: 2rem;
        height: 2rem;
        border:1px solid ${color_Blue_Dark};
        margin-left :1rem;
    }
`;

export const InfoTitle = styled.span`
    position: absolute;
    left:35%;
    font-family: 'Times New Roman', Times, serif;
    font-size:2.0rem;
    font-weight: 700;
    
    @media only screen and (max-width:400px){
        font-size: 1.2rem;
    }
`;