export const selectionsType = {
    //Change
    CHANGE_SELECTOR_SHOW: 'CHANGE_SELECTOR_SHOW',
    CHANGE_SELECTIONS_SHOW: 'CHANGE_SELECTIONS_SHOW',

    //save
    SAVE_SELECT_CATEGORI: 'SAVE_SELECT_CATEGORI',

    SAVE_SELECT_ROOMS: 'SAVE_SELECT_ROOMS',

    SAVE_SELECT_TROPO: 'SAVE_SELECT_TROPO',
    SAVE_SELECT_XOROS: 'SAVE_SELECT_XOROS',
    SAVE_SELECT_PERIODOS: 'SAVE_SELECT_PERIODOS',
    SAVE_SELECT_KINOXRISTI: 'SAVE_SELECT_KINOXRISTI',
    SAVE_SELECT_EKDILOSIS: 'SAVE_SELECT_EKDILOSIS',

    //Generate 
    GENARATE_RANDOM_NUMBER: 'GENARATE_RANDOM_NUMBER',

    //Clear
    CLEAR_SELECTED_ITEMS: 'CLEAR_SELECTED_ITEMS',
}