import styled from 'styled-components';

//Colors
import { color_Grey_Medium } from '../../styles/colors';

export const SumCostStyle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2.5rem;
`;

export const SpanFPA = styled.span`
    font-size:2rem ;
    color:${color_Grey_Medium};
    letter-spacing: 1.7px;

    @media only screen and (max-width:500px){
        font-size:1.7rem;
        letter-spacing: 1px;
    }
    @media only screen and (max-width:350px){
        font-size:1.4rem;
        letter-spacing: .5px;
    }
`;

