import styled from 'styled-components';

import {
    color_White,
    color_Grey_Medium,
    color_Blue_Ligth,
    color_Blue_Medium,
    color_Blue_Dark,
    // color_Green_Ligth,
    color_Grey_Ligth,
    color_Grey_Dark,
} from '../../../styles/colors';

export const BtnDropBoxStyle = styled.div`
    /* display: flex; */
    ${({ showStep }) => showStep === true ? { display: 'flex' } : { display: 'none' }}
    align-items: center;
    justify-content: left;
    user-select: none;
    width: 100%;
    &:not(:last-child){
        margin-bottom:1.5rem;
    }
`;

export const ComboBtnPanelStyle = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items:flex-start;
    width: 100%;

    @media only screen and (max-width:900px){
        flex-direction: column;
        justify-content:center;
    }
    @media only screen and (max-width:650px){
        width: 90%;
    }

    
`;

export const BtnNumber = styled.span`
    min-width: 4rem;
    font-size: 4rem;
    color:${color_Grey_Medium};
    /* margin-right: 2rem; */

    @media only screen and (max-width:960px){
        min-width: 1rem;
        font-size: 3rem;
    }
    @media only screen and (max-width:600px){
        font-size: 2.5rem;
    }
`;

export const BtnWithSelections = styled.div`
    margin-left: 2rem;
    position: relative;
    width: auto;
    @media only screen and (max-width:900px){
        &:nth-child(2){
            margin-top: 1.5rem;
        }
    }

    @media only screen and (max-width:450px){
        margin-left: 1rem;
    }
    @media only screen and (max-width:350px){
        margin-left: .4rem;
    }
`;

export const BtnBase = styled.div`
    ${({ mode }) => mode === 'small' ?
        { width: '30rem' }
        :
        { width: '65rem' }
    }
    /* width:65rem; */
    height: 5.7rem;
    padding:1.2rem;
    display:flex;
    justify-content: space-between;
    align-items: center;

    background-color: ${color_Blue_Medium};
    color:${color_White};

    border-radius: .5rem;
    border:1px solid ${color_Grey_Medium};
    cursor: pointer;

    &:hover{
        background-color: ${color_Blue_Ligth};
    }

    &:active{
        background-color: ${color_Blue_Dark};
    }

    @media only screen and (max-width:800px){
        max-width: 100%;
    }

    @media only screen and (max-width:650px){
        height: auto;
        width: 100%;
    }
    @media only screen and (max-width:400px){
    padding: 1rem;
    }
`;

export const BtnTitle = styled.h3`
    text-align: left;
    font-weight: 400;
    font-size: 3rem;
    letter-spacing: 1.5px;

    @media only screen and (max-width:960px){
        letter-spacing: 1px;
        font-size: 2.8rem;
    }
    @media only screen and (max-width:550px){
        letter-spacing: .6px;
        font-size: 2.4rem;
    }
    @media only screen and (max-width:450px){
        letter-spacing: .2px;
        font-size: 2rem;
    }
    @media only screen and (max-width:400px){
        letter-spacing: 0;
        font-size: 1.7rem;
    }
    @media only screen and (max-width:350px){
        font-size: 1.5rem;
    }
`;

export const BtnSelector = styled.div`
    ${({ show }) => show === true ? { display: 'inline-block' } : { display: 'none' }}
    position: absolute;
    width: 100%;
    background-color: ${color_White};
    padding: .2rem .5rem;
    max-height: 40rem;
    overflow-y: scroll;
    z-index:1;
    border-bottom-left-radius:1rem;
    border-bottom-right-radius:1rem;
    border:1px solid ${color_Grey_Ligth}
`;

export const SetSelectionStyle = styled.div`
    padding:.2rem .5rem;
    background-color: ${color_White};
    border-bottom:1px solid ${color_Grey_Dark};

    cursor: pointer;
    border-radius:.5rem;

    font-size: 1.8rem;
    font-weight: 400;
    letter-spacing: 1px;

    &:hover{
        background-color: ${color_Blue_Ligth};
    }

    &:active{
        background-color: ${color_Blue_Medium};
    }
    @media only screen and (max-width:400px){
        letter-spacing: 0;
        font-size: 1.7rem;
    }
    @media only screen and (max-width:350px){
        font-size: 1.5rem;
    }
`;