import React, { useRef } from 'react';
import ReactDomServer from 'react-dom/server';

//Librari to create pdf file for download
import jsPDF from 'jspdf';

import '../../fonts/converted/OpenSans-Regular-normal';
import '../../fonts/converted/OpenSans-Light-normal';
import '../../fonts/converted/OpenSans-Bold-normal';
import '../../fonts/converted/OpenSans-SemiBold-normal';
import '../../fonts/converted/OpenSans-ExtraBold-bold';

//Style
import {
    PDFPageStyle,
    CompanyPanelStyle,
    MonadikosNumberPanel,
    SpecialNumberStyle,
    DetailsPanelStyle,
    DetailsTextStyle,
    PanalOrganismoyStyle,
    OrganismosDetails,
    OrganismosNameStyle,
    OrganismosCostStyle,
    BankAccoundStyle,
    BankPanelStyle,
    BankDetailsStyle,
    DetailsAllStyle,

} from './create.pdf.style';

export const createPDF = (specialNumber, priceEdem = 0, priceGea = 0, priceAthina = 0, filename = "emusic.gr.pdf") => {

    // const inputRef = useRef(null);

    const pdf = new jsPDF("p", "px", 'a4');
    const el = generatePDF(specialNumber, priceEdem, priceGea, priceAthina);

    pdf.html(ReactDomServer.renderToStaticMarkup(el), {
        callback: function (doc) {
            doc.save(filename);
        },
        x: 0,
        y: 0
    });

};

export function generatePDF(specialNumber, priceEdem = 0, priceGea = 0, priceAthina = 0) {
    return (
        <PDFPageStyle id='createPDF' >
            <CompanyPanelStyle>
                <h1>emusic.gr</h1>
                <h3>Ενδεικτική τιμολόγηση για τα δικαιώματα της επιχείρησής σας</h3>
            </CompanyPanelStyle>

            <MonadikosNumberPanel>
                <h3>ΜΟΝΑΔΙΚΟΣ ΑΡΙΘΜΟΣ ΕΝΔΕΙΚΤΙΚΗΣ ΤΙΜΟΛΟΓΗΣΗΣ</h3>
                <SpecialNumberStyle>({specialNumber})</SpecialNumberStyle>
            </MonadikosNumberPanel>

            <DetailsPanelStyle>
                <DetailsTextStyle>
                    Σύμφωνα με τα δεδομένα τα οποία εισάγατε στην φόρμα του <strong>emusic.gr</strong>, η επιχείρησή σας
                    μπορεί να αδειοδοτηθεί, αφού καταβάλει σε κάθε οργανισμό τα εξής δικαιώματα:
                </DetailsTextStyle>
            </DetailsPanelStyle>

            <PanalOrganismoyStyle>
                <OrganismosDetails>
                    <OrganismosNameStyle>Ένωση Δικαιούχων - ΕΔΕΜ</OrganismosNameStyle>
                    <h5>(τηλ: 211 19 06 700)</h5>
                    <OrganismosCostStyle>Ποσό: {priceEdem} €</OrganismosCostStyle>
                </OrganismosDetails>

                <BankAccoundStyle>ΤΡΑΠΕΖΙΚΟΙ ΛΟΓΑΡΙΑΣΜΟΙ Ε.Δ.Ε.Μ. ΔΙΚΑΙΟΥΧΟΣ: ΕΝΩΣΗ ΔΙΚΑΙΟΥΧΩΝ ΕΡΓΩΝ ΜΟΥΣΙΚΗΣ</BankAccoundStyle>

                <BankPanelStyle>
                    <div><span>- ΕΘΝΙΚΗ ΤΡΑΠΕΖΑ</span><span>GR5001100400000004001828344</span></div>
                    <div><span>- ALPHA BANK</span><span>GR5001401030103002002020121</span></div>
                    <div><span>- ΤΡΑΠΕΖΑ ΠΕΙΡΑΙΩΣ</span><span>GR9301717490006749148190570</span></div>
                    <div><span>- EUROBANK</span><span>GR5302601010000560201293351</span></div>
                </BankPanelStyle>

                <BankDetailsStyle>* Σε περίπτωση εμβάσματος ως αιτιολογία πρέπει να αναγράφεται ο Α.Φ.Μ.της επιχείρησης</BankDetailsStyle>
            </PanalOrganismoyStyle>

            <PanalOrganismoyStyle>
                <OrganismosDetails>
                    <OrganismosNameStyle>Oργανισμός Συλλογικής Διαχείρισης και Είσπραξης του Συγγενικού Δικαιώματος G.E.A.</OrganismosNameStyle>
                    <h5>(τηλ:210 67 52 820)</h5>
                    <OrganismosCostStyle>Ποσό:{priceGea} €</OrganismosCostStyle>
                </OrganismosDetails>

                <BankAccoundStyle>ΤΡΑΠΕΖΙΚΟΙ ΛΟΓΑΡΙΑΣΜΟΙ G.E.A.</BankAccoundStyle>

                <BankPanelStyle>
                    <div><span>- ΕΘΝΙΚΗ ΤΡΑΠΕΖΑ</span><span>GR5201100990000009948008264</span></div>
                    <div><span>- ALPHA BANK</span><span>GR3201401580158002002007010</span></div>
                    <div><span>- ΤΡΑΠΕΖΑ ΠΕΙΡΑΙΩΣ</span><span>GR2001716480006648125739484</span></div>
                    <div><span>- EUROBANK</span><span>GR2202606300000040200977509</span></div>
                </BankPanelStyle>

                <BankDetailsStyle>* Σε περίπτωση εμβάσματος ως αιτιολογία πρέπει να αναγράφεται ο Α.Φ.Μ.της επιχείρησης</BankDetailsStyle>
            </PanalOrganismoyStyle>

            <PanalOrganismoyStyle>

                <OrganismosDetails>
                    <OrganismosNameStyle>Οργανισμού Συλλογικής Διαχείρισης Δημιουργών Θεατρικών και Οπτικοακουστικών Έργων - ΑΘΗΝΑ</OrganismosNameStyle>
                    <h5>(τηλ: 210 36 18 368)</h5>
                    <OrganismosCostStyle>Ποσό:{priceAthina} €</OrganismosCostStyle>
                </OrganismosDetails>

                <BankAccoundStyle>ΤΡΑΠΕΖΙΚΟΙ ΛΟΓΑΡΙΑΣΜΟΙ ΑΘΗΝΑ: Οργανισμός Συλλογικής Διαχείρισης Αθηνά</BankAccoundStyle>

                <BankPanelStyle>
                    <div><span>- ALPHA BANK</span><span>GR2601404410441002002001806</span></div>
                    <div><span>- ΤΡΑΠΕΖΑ ΠΕΙΡΑΙΩΣ</span><span>GR2401720910005091037600711</span></div>
                </BankPanelStyle>

                <BankDetailsStyle>* Σε περίπτωση εμβάσματος ως αιτιολογία πρέπει να αναγράφεται ο Α.Φ.Μ.της επιχείρησης</BankDetailsStyle>
            </PanalOrganismoyStyle>

            <DetailsAllStyle>
                <q><strong>Σημαντική Σημείωση:</strong> Η τιμολόγηση δεν αποτελεί δεσμευτική τιμή για τον κάθε Οργανισμό και η
                    οριστικοποίηση της τελικής τιμής πρέπει να γίνεται σύμφωνα με τα αντίστοιχα τμήματα του κάθε
                    Οργανισμού μετά από επικοινωνία του χρήστη με τους παραπάνω Οργανισμούς</q>
            </DetailsAllStyle>

        </PDFPageStyle>
    )
};