import { extraChoiseTypes } from './extra.choise.types';

const INITIAL = {
    extraChoise: {
        evaradio: { id: 0, name: 'Υπηρεσία evaradio.gr ( +20€ Έτος )', checked: false, price: 20 },
        nomikes: { id: 1, name: 'Νομικές Συμβουλές ( +20€ Έτος )', checked: false, price: 20 }
    }
};

const extraChoiseReducer = (state = INITIAL, action) => {
    switch (action.type) {
        case extraChoiseTypes.CHANGE_EVARADIO_CHECKED:
            // changeEvaradioPrice(state, action.payload);
            return {
                ...state, extraChoise: {
                    ...state.extraChoise,
                    evaradio: {
                        ...state.extraChoise.evaradio, checked: action.payload
                    }
                }
            }

        case extraChoiseTypes.CHANGE_NOMIKES_CHECKED:
            return {
                ...state, extraChoise: {
                    ...state.extraChoise,
                    nomikes: {
                        ...state.extraChoise.nomikes, checked: action.payload
                    }
                }
            };

        default:
            return state;
    }

};

export default extraChoiseReducer;