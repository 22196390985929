import { stepstypes } from './steps.types';

export const changeStepNext = () => ({
    type: stepstypes.STEP_NEXT,
});

export const changeStepPreviews = () => ({
    type: stepstypes.STEP_PREVIEW,
});

export const openNextBtn = () => ({
    type: stepstypes.OPEN_NEXT_BTN
});

export const closeNextBtn = () => ({
    type: stepstypes.CLOSE_NEXT_BTN
});