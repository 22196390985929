const mixanikiMoysiki = [
    { id: 0, xoros: { start: 1, end: 30 }, edem: { cost: 348 }, gea: { cost: 1500 } },
    { id: 1, xoros: { start: 31, end: 60 }, edem: { cost: 468 }, gea: { cost: 1500 } },
    { id: 2, xoros: { start: 61, end: 90 }, edem: { cost: 576 }, gea: { cost: 1500 } },
    { id: 3, xoros: { start: 91, end: 120 }, edem: { cost: 696 }, gea: { cost: 2100 } },
    { id: 4, xoros: { start: 121, end: 150 }, edem: { cost: 816 }, gea: { cost: 2100 } },
    { id: 5, xoros: { start: 151, end: 200 }, edem: { cost: 1080 }, gea: { cost: 2100 } },
    { id: 6, xoros: { start: 201, end: 250 }, edem: { cost: 1620 }, gea: { cost: 2500 } },
];

const aplaMixanikaMesa = [
    { id: 0, xoros: { start: 1, end: 50 }, edem: { cost: 252 }, gea: { cost: 100 } },
    { id: 1, xoros: { start: 51, end: 100 }, edem: { cost: 300 }, gea: { cost: 150 } },
    { id: 2, xoros: { start: 101, end: 150 }, edem: { cost: 324 }, gea: { cost: 200 } },
    { id: 3, xoros: { start: 151, end: 200 }, edem: { cost: 360 }, gea: { cost: 200 } },
    { id: 4, xoros: { start: 201, end: 250 }, edem: { cost: 420 }, gea: { cost: 250 } },
];

const discJockey = [
    { id: 0, xoros: { start: 1, end: 30 }, edem: { cost: 492 }, gea: { cost: 1500 } },
    { id: 1, xoros: { start: 31, end: 60 }, edem: { cost: 660 }, gea: { cost: 1500 } },
    { id: 2, xoros: { start: 61, end: 90 }, edem: { cost: 876 }, gea: { cost: 1500 } },
    { id: 3, xoros: { start: 91, end: 120 }, edem: { cost: 1164 }, gea: { cost: 2100 } },
    { id: 4, xoros: { start: 121, end: 150 }, edem: { cost: 1608 }, gea: { cost: 2100 } },
    { id: 5, xoros: { start: 151, end: 200 }, edem: { cost: 2232 }, gea: { cost: 2100 } },
    { id: 6, xoros: { start: 201, end: 250 }, edem: { cost: 3360 }, gea: { cost: 2500 } },
];

const zontaniKaiMixanikiMoysiki = [
    { id: 0, xoros: { start: 1, end: 50 }, edem: { cost: 1428 }, gea: { cost: 1500 } },
    { id: 1, xoros: { start: 51, end: 100 }, edem: { cost: 1608 }, gea: { cost: 1500 } },
    { id: 2, xoros: { start: 101, end: 150 }, edem: { cost: 2136 }, gea: { cost: 1500 } },
    { id: 3, xoros: { start: 151, end: 200 }, edem: { cost: 2700 }, gea: { cost: 2100 } },
    { id: 4, xoros: { start: 201, end: 250 }, edem: { cost: 3408 }, gea: { cost: 2100 } },
];

const paradosiakaKafeneia = [
    { id: 0, xoros: { start: 1, end: 50 }, edem: { cost: 96 }, gea: { cost: 100 } },
    { id: 1, xoros: { start: 51, end: 100 }, edem: { cost: 108 }, gea: { cost: 150 } },
    { id: 2, xoros: { start: 101, end: 150 }, edem: { cost: 132 }, gea: { cost: 200 } },
    { id: 3, xoros: { start: 151, end: 200 }, edem: { cost: 156 }, gea: { cost: 200 } },
    { id: 4, xoros: { start: 201, end: 250 }, edem: { cost: 168 }, gea: { cost: 250 } },
];

const takeAwayXoris = [
    { id: 0, xoros: { start: 1, end: 50 }, edem: { cost: 96 }, gea: { cost: 100 } },
    { id: 1, xoros: { start: 51, end: 100 }, edem: { cost: 108 }, gea: { cost: 150 } },
];
// const takeAwayXoris = [
//     { id: 0, xoros: { start: 1, end: 50 }, edem: { cost: 132 }, gea: { cost: 100 } },
//     { id: 1, xoros: { start: 51, end: 100 }, edem: { cost: 156 }, gea: { cost: 150 } },
// ];

const hotelsForYear = [
    { id: 0, catigoria: { stars: 5 }, edem: { costForRoom: 3.7595 }, gea: { costForRoom: 73 }, athina: { costForRoom: 21.42 } },
    { id: 1, catigoria: { stars: 3 }, edem: { costForRoom: 2.6645 }, gea: { costForRoom: 36.50 }, athina: { costForRoom: 19.07 } },
    { id: 2, catigoria: { stars: 1 }, edem: { costForRoom: 2.1900 }, gea: { costForRoom: 25.55 }, athina: { costForRoom: 17.02 } },
];
const kinoxristiForYear = [
    { id: 0, xoros: { start: 1, end: 100 }, edem: { stars5_4: { cost: 240 }, stars3: { cost: 180 }, stars2_1: { cost: 120 } }, gea: { cost: 150 } },
    { id: 1, xoros: { start: 101, end: 200 }, edem: { stars5_4: { cost: 324 }, stars3: { cost: 264 }, stars2_1: { cost: 204 } }, gea: { cost: 200 } },
    { id: 2, xoros: { start: 201, end: 300 }, edem: { stars5_4: { cost: 408 }, stars3: { cost: 348 }, stars2_1: { cost: 288 } }, gea: { cost: 250 } },
    { id: 3, xoros: { start: 301, end: 400 }, edem: { stars5_4: { cost: 492 }, stars3: { cost: 432 }, stars2_1: { cost: 372 } }, gea: { cost: 310 } },
    { id: 4, xoros: { start: 401, end: 0 }, edem: { stars5_4: { cost: 576 }, stars3: { cost: 516 }, stars2_1: { cost: 456 } }, gea: { cost: 400 } },
];

const hotelForMonths = [
    { id: 0, catigoria: { stars: 5 }, edem: { costForRoom: 1.8797 }, gea: { costForRoom: 36.50 }, athina: { costForRoom: 10.71 } },
    { id: 1, catigoria: { stars: 3 }, edem: { costForRoom: 1.3322 }, gea: { costForRoom: 18.25 }, athina: { costForRoom: 9.53 } },
    { id: 2, catigoria: { stars: 1 }, edem: { costForRoom: 1.0950 }, gea: { costForRoom: 12.77 }, athina: { costForRoom: 8.51 } },
];

const kinoxristiForMonths = [
    { id: 0, xoros: { start: 1, end: 100 }, edem: { stars5_4: { cost: 120 }, stars3: { cost: 90 }, stars2_1: { cost: 90 } }, gea: { cost: 105 } },
    { id: 1, xoros: { start: 101, end: 200 }, edem: { stars5_4: { cost: 162 }, stars3: { cost: 132 }, stars2_1: { cost: 102 } }, gea: { cost: 140 } },
    { id: 2, xoros: { start: 201, end: 300 }, edem: { stars5_4: { cost: 204 }, stars3: { cost: 174 }, stars2_1: { cost: 144 } }, gea: { cost: 175 } },
    { id: 3, xoros: { start: 301, end: 400 }, edem: { stars5_4: { cost: 246 }, stars3: { cost: 216 }, stars2_1: { cost: 186 } }, gea: { cost: 217 } },
    { id: 4, xoros: { start: 401, end: 0 }, edem: { stars5_4: { cost: 288 }, stars3: { cost: 258 }, stars2_1: { cost: 228 } }, gea: { cost: 280 } },
];

export const dataCostAll = {
    others: {
        mixanikiMoysiki: [...mixanikiMoysiki],
        aplaMixanikaMesa: [...aplaMixanikaMesa],
        discJockey: [...discJockey],
        zontaniKaiMixanikiMoysiki: [...zontaniKaiMixanikiMoysiki],
        takeAwayXoris: [...takeAwayXoris],
        paradosiakaKafeneia: [...paradosiakaKafeneia],
    },
    hotels: {
        year: { hotelsForYear: [...hotelsForYear], kinoxristiForYear: [...kinoxristiForYear] },
        months: { hotelForMonths: [...hotelForMonths], kinoxristiForMonths: [...kinoxristiForMonths] },
    }
};