import React from 'react';

//Components
import { CheckBox } from './checkBox/check.box.component';
import { InfoExtra } from './infoBtn/infoBtn.component';

//Styles
import { ExtraRowTitle, ExtraRowDetails, ExtraCostStyle, EtxraLinkStyle } from './extraRow.style';

const ExtraRow = ({ checked, title, link, clickCheckBox, showInfo = true, extraLinkTitle, extraLinkPath, extraLinkShow = true }) => {

    return (
        <ExtraCostStyle>
            <CheckBox checked={checked} clickCheckBox={clickCheckBox} />
            <ExtraRowDetails>
                <ExtraRowTitle>{title}</ExtraRowTitle>
                {
                    extraLinkShow && <EtxraLinkStyle href={extraLinkPath} target="_blanc">{extraLinkTitle}</EtxraLinkStyle>
                }
                {
                    showInfo && <InfoExtra link={link} />
                }
            </ExtraRowDetails>
        </ExtraCostStyle>
    )
}

export default ExtraRow;