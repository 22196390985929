
//Πρέπει να ελέγχει και να ανοιγει τα κατάλληλα πεδία για την επιλεγμένη Κατηγορία
export const findSelectionForTropos = (selectionsArray, categoriID) => {
    let newArrayForSelection = [];

    switch (categoriID) {
        case 0 || 1 || 3:
            newArrayForSelection = [...selectionsArray]
            break;

        case 2:
            newArrayForSelection = selectionsArray.map(item => {
                if (item.id === 0) {
                    return item;
                }
                return null
            });
            break;

        case 4:
            newArrayForSelection = selectionsArray.map(item => {
                if (item.id === 0 || item.id === 3) {
                    return item;
                }
                return null
            });
            break;

        default:
            newArrayForSelection = [...selectionsArray]
            break;
    }

    return newArrayForSelection;
}

//Πρέπει να φτιάχνει τον χώρο για τα καταστήματα ανάλλογα με τις επιλογές που παίρνει
export const setXorosTMSelections = (first = 0, last = 250, step) => {
    const newArrayForSelection = [];
    let newObject = {};
    let j = 0;

    for (let i = first; i < last; i = i + step) {
        const start = i + 1;
        const end = i + step;

        newObject = {
            id: j, name: `${start}-${end}`, start:start, end:end
        };

        newArrayForSelection.push(newObject);
        j++;
    }

    return newArrayForSelection;
}
