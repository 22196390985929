//Redux
import { combineReducers } from 'redux'

//Redux-persist
// import { persistReducer } from 'redux-persist';

// import storage from 'redux-persist/lib/storage';

// Reducers
import stepsReducer from './steps/steps.reducer';
import selectionsReducer from './selections/selections.reducer';
import extraChoiseReducer from './extraChoise/extra.choise.reducer';
import resultReducer from './result/result.reducer';

//@key = to key gia na kanoyme retrive ta dedomena
//@storage = se poio meros 8eloyme na apo8ikeysoyme ta dedomena
//@whiteList = bazoyme toys reducer poy 8eloyme na pername sto localstorage

// const persistConfig = {
//     key: 'karekles-root',
//     storage: storage,
//     whiteList: ['cart'],
//     blackList: ['homeSliderAnimation']
// }

// const persistConfigCartPage = {
//     key: 'karekles-cartPage',
//     storage: storage,
//     whiteList: ['cartPage'],
// };


// const persistConfigOrder = {
//     key: 'karekles-order',
//     storage: storage,
//     whiteList: ['order']
// };

const rootReducers = combineReducers({
    steps: stepsReducer,
    selections: selectionsReducer,
    extraChoise: extraChoiseReducer,
    result: resultReducer,
    // cart: cartReducer,
    // cart: persistReducer(persistConfig, cartReducer),
});

export default rootReducers;