import { Component } from 'react';

//Components
//Steps
import Steps from '../../components/steps/steps.component';
//panel
import PanelForStep from '../../components/panel/panel.component';


//Style
import { HomeComponent } from './home.page.style';

export class HomePage extends Component {

    render() {
        return (
            <HomeComponent className='timologisi'>
                <Steps/>

                <PanelForStep/>
                                    
            </HomeComponent>
        )
    }
}