import styled, { css } from 'styled-components';

//Colors
import {
    color_Grey_Medium, color_Grey_Dark,
    color_Blue_Ligth, color_Blue_Medium, color_Blue_Dark
} from '../../../../styles/colors';

const defaultStyle = css`
    width:2rem;
    height: 2rem;
    margin-right: 2rem;

    @media only screen and (max-width:350px){
        margin-right: 1rem;
    }
`;

export const CheckBoxEnable = styled.div`
    ${defaultStyle} 
    cursor: pointer;
`;

export const CheckBoxDisable = styled.div`
    ${defaultStyle}

    border:2px solid ${color_Grey_Medium};
    border-radius: 5px;
    cursor: pointer;

    &:hover{
        border:2px solid ${color_Blue_Medium};
    }
`;