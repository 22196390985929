import { createSelector } from 'reselect';

const selectResultState = state => state.result;

//Select all Results for costs
export const selectAllResult = createSelector(
    [selectResultState],
    result => result
);

//Select The Random Number
export const selectRandomNumber = createSelector(
    [selectResultState],
    result => result
);