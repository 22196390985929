import styled from "styled-components";

export const ResultPanelStyle = styled.div`
    margin:6rem auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;

    @media only screen and (max-width:900px){
        width:55%;
    }
    @media only screen and (max-width:650px){
        width:65%;
    }
    @media only screen and (max-width:550px){
        width:75%;
    }
    @media only screen and (max-width:350px){
        width:85%;
    }

`;