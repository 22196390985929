//Πρέπει να αλλάζει το show ανάλογα με την επιλογά selection που έχουμε κάνει
// export const changeSelectionShow = (show, selectionsArray) => {
//     return show;
// }

//Αποθηκεύει την επιλογή της Κατηγορίας
export const saveSelectCategori = (state, categori) => {
    const newState = { ...state, selected: { ...state.selected, categori: { ...categori } } }
    return newState;
};

//Αποθηκεύη τα δωμάτια που επέλεξε ο χρήστης
export const saveSelectRooms = (state, rooms) => {
    return { ...state, selected: { ...state.selected, rooms: rooms } }
};

//Αποθηκεύει την επιλογή του Σταθερου Τρόπου μετάδοσης Μουσικής
export const saveSelectTropo = (state, tropos) => {
    const newState = { ...state, selected: { ...state.selected, tropos: { ...tropos } } }
    return newState;
};
//Αποθηκεύει την επιλογή του Χώρου
export const saveSelectXoros = (state, xoros) => {
    const newState = { ...state, selected: { ...state.selected, xoros: { ...xoros } } }
    return newState;
};
//Αποθηκεύει την επιλογή της Περιόδου λειτουργίας
export const saveSelectPeriodos = (state, periodos) => {
    const newState = { ...state, selected: { ...state.selected, periodos: { ...periodos } } }
    return newState;
};

//Αποθηκεύει την επιλογή του Κοινόχρηστου χώρου
export const saveSelectKinoxristi = (state, kinoxristi) => {
    const newState = { ...state, selected: { ...state.selected, kinoxristi: { ...kinoxristi } } }
    return newState;
};

//Αποθηκεύει την επιλογή των Εκδιλώσεων 
export const saveSelectEkdilosis = (state, ekdilosi) => {
    const newState = { ...state, selected: { ...state.selected, ekdilosis: { ...ekdilosi } } }
    return newState;
};


//Πρέπει να αλλάζει το value στο showSelections του selector σε Show or Hiden
export const changeShowSelections = (state, selectID) => {
    const newState = {
        ...state, selectors: [...state.selectors.map((item, idx) => {
            if (item.id === selectID) {
                item.showSelections = !item.showSelections //{ ...selectItem, showSelections: !selectItem.showSelections }
            } else {
                item.showSelections = false;
            };

            item.id = idx;
            return item;
        })]
    }
    return newState;
};

//Πρεπει να βλέπει ποιός είναι ο επόμενος selector που θα εμφανιστεί
export const changeNextSelectorShow = (state, selectorID, value = true) => {
    const newState = {
        ...state, selectors: [
            ...state.selectors.map((selector, idx) => {
                if (selector.id === selectorID) {
                    selector.show = value
                }
                selector.id = idx
                return selector
            })]
    };

    return newState;
};

export const generateRandomNumber = (state, digits = 16, maxNum = 10) => {
    let newNumber = 0;
    let password;

    for (let i = 0; i < digits; i++) {
        newNumber = String(parseInt(Math.random() * maxNum));
        if (password !== undefined) {
            password = password + newNumber;
        } else {
            password = newNumber;
        }
    }
    return { ...state, selected: { ...state.selected, randomNumber: password } };
}