import styled, { css } from 'styled-components';

//Colors
import { color_Grey_Medium, color_Green_Medium, color_Green_Ligth } from '../../../styles/colors';

const defaultPosition = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
`

export const SecondPanelStyle = styled.div`
display: grid;
    grid-template-rows: repeat(2,minmax(10rem,max-content));
    grid-template-columns: repeat(2,50%);
    width: 100%;
    user-select: none;

    @media only screen and (max-width:900px){
        grid-template-rows:minmax(10rem,max-content); 
        grid-template-columns: auto;

        justify-content: center;
        row-gap: 2rem;
        padding: .5rem;
    }
`;

export const StandarCostStyle = styled.div`
    ${defaultPosition}
    align-items: flex-end;
    grid-row: 1/2;
    grid-column: 1/2;

    @media only screen and (max-width:900px){
        grid-row:1/2;
        grid-column: 1/-1;
    }

`;

export const ExtraCostStyle = styled.div`
    ${defaultPosition}
    
    grid-row: 1/2;
    grid-column: 2/3;
    @media only screen and (max-width:900px){
        grid-row: 2/3;
        grid-column: 1/-1;
    }

`;

export const SumCostStyle = styled.div`
    ${defaultPosition}
    
    grid-row: 2/3;
    grid-column:1/3;

    @media only screen and (max-width:900px){
        grid-row:3/4;
        grid-column: 1/-1;
    }
`;