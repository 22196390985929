import React from 'react';

//Style
import {
    ButtonChangeStyle,
    ButtonTitleStyle
} from './btn_change_step.style';

export const ButtonChange = ({ btnName = "none", side = "rigth", clicked }) => {

    return (
        <ButtonChangeStyle side={side} onClick={() => clicked()}>
            <ButtonTitleStyle>{btnName}</ButtonTitleStyle>
        </ButtonChangeStyle>
    )
}