import styled from 'styled-components';
import { color_White, color_Coral_Medium, color_Coral_Ligth, color_Coral_Dark, color_Grey_Medium, color_Grey_Ligth } from '../../../styles/colors';

export const BtnPanelStyle = styled.div`
    user-select: none;
    width: 100%;
    text-align: center;
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: 1.5px;
    padding: .5rem 2rem;
    color: ${color_White};
    ${({enable}) => enable ?
        { backgroundColor: color_Coral_Medium, cursor: 'pointer' }
        :
        { backgroundColor: color_Grey_Medium, cursor: 'default' }
    };
    /* background-color: ${color_Grey_Medium}; */
    border-radius:.5rem;
    border: 1px solid ${color_Grey_Ligth};

    &:hover{
        ${({enable}) => enable && { backgroundColor: color_Coral_Ligth }}
    };

    &:active{
        ${({enable}) => enable && { backgroundColor: color_Coral_Dark }}
    }

    
`;