import { dataCostAll } from '../../../redux/result/result.data';

import { changeValueEdem, changeValueGea, changeValueAthina, changeValueSum } from '../../../redux/result/result.actions';

import { store } from '../../../redux/store/store';

export const findCostForSelections = async (selected, choise) => {
    //Να βλέπει την Επιλεγμένη (Κατηγορία)
    // ------------------------------------------------------------------------------------------------------------------------------------//
    //Και να ξεχωρίζει τι επιλογή :

    if (selected.categori.id < 5) {
        //---------------------------------------------------------------------------------------------------------------------------------//
        // Α ) βάση τα εξής : ( cafe-bar -id:0, Restaurant -id:1, Take Away -id:2, Ταβέρνα - Ουζερί -id:3, Παραδοσιακό Καφενείο -id:4 )
        //---------------------------------------------------------------------------------------------------------------------------------//
        // 1 ) Να πέρνει την επιλεγμένη (Κατηγορία)
        // 2 ) Να πέρνει το κοστος για την επιλογή (Σταθερό Τρόπο Μετάδοσης Μουσικής)
        const costTroposArray = await findTroposCost(selected);
        // 3 ) Να πέρνει τον επιλεγμένο (Χώρο Πελατών)
        const costXoros = await findXorosCost(selected, costTroposArray);
        // 4 ) Να πέρνει την (Περίοδο Λειτουργίας)
        const sumCostForChoise = await findPeriodosCost(costXoros, choise.num);

        // ΤΕΛΟΣ ΝΑ ΥΠΟΛΟΓΙΖΕΙ ΤΟ ΚΟΣΤΟΣ ΚΑΙ ΝΑ ΤΟ ΠΕΡΝΑΙ ΣΤΗ REDUX
        //Πρέπει να περνάει τις τιμές στην Redux
        store.dispatch(changeValueEdem(sumCostForChoise.edem));
        store.dispatch(changeValueGea(sumCostForChoise.gea));
        store.dispatch(changeValueSum());

    } else if (selected.categori.id >= 5) {
        //---------------------------------------------------------------------------------------------------------------------------------//
        // Β ) Η με βάση τα εξής : ( Ξενοδοχεία 5***** ή 4**** -id:5, Ξενοδοχεία 3*** -id:6, Ξενοδοχεία 2** ή 1* -id:7)
        //---------------------------------------------------------------------------------------------------------------------------------//

        // 1 ) Να πέρνει την επιλεγμένη (Κατηγορία)
        const costForHotels = findTheHotelsCost(selected);

        // 2 ) Να πέρνει τα (Δωμάτια)
        // 3 ) Να πέρνει τους (Κοινόχρηστους Χώρους)
        const costForKinoxristous = findKinoxristiCost(selected);

        // 4 ) Να βλέπει αν (Διοργανώνετε Εκδηλώσεις)
        // 5 ) Να πέρνει την (Περίοδο Λειτουργίας)
        const costHotelperiodos = findHotelPeriodosCost({ year: { hotel: costForHotels.hotelPerYear, kinoxristi: costForKinoxristous.kinoxristiPerYear }, sesson: { hotel: costForHotels.hotelForSesson, kinoxristi: costForKinoxristous.kinoxristiPerMonth } }, choise.num);

        const costHotelsAll = findAllCostForHotel(selected.rooms.name, costHotelperiodos);
        // ΤΕΛΟΣ ΝΑ ΥΠΟΛΟΓΙΖΕΙ ΤΟ ΚΟΣΤΟΣ ΚΑΙ ΝΑ ΤΟ ΠΕΡΝΑΙ ΣΤΗ REDUX
        store.dispatch(changeValueEdem(costHotelsAll.edem));
        store.dispatch(changeValueGea(costHotelsAll.gea));
        store.dispatch(changeValueAthina(costHotelsAll.athina));
        store.dispatch(changeValueSum());
    }
};
//Εάν η επιλογή είναι A ) θα πρέπει να πέρνει τα παρακάτω :
const findTroposCost = async (selected) => {
    // 2 ) Να πέρνει την επιλογή για τον (Σταθερό Τρόπο Μετάδοσης Μουσικής)
    switch (selected.categori.id) {
        //cafe-bar -id:0, Restaurant -id:1, Ταβέρνα - Ουζερί -id:3
        case 0:
        case 1:
        case 3:

            switch (selected.tropos.id) {
                case 0:
                    return [...dataCostAll.others.aplaMixanikaMesa];

                case 1:
                    return [...dataCostAll.others.mixanikiMoysiki];

                case 2:
                    return [...dataCostAll.others.discJockey];

                case 3:
                    return [...dataCostAll.others.zontaniKaiMixanikiMoysiki];

                default:
                    return -1;
            }

        //Take Away -id:2
        case 2:
            return [...dataCostAll.others.takeAwayXoris];

        //Παραδοσιακό Καφενείο -id:4
        case 4:
            switch (selected.tropos.id) {
                case 0:
                    return [...dataCostAll.others.paradosiakaKafeneia];

                case 3:
                    return [...dataCostAll.others.zontaniKaiMixanikiMoysiki];

                default:
                    return -1;
            }
        default:
            return -1
    }
};
const findXorosCost = async (selected, costTroposArray) => {
    // 3 ) Να βρίσκει το κόστος για τον επιλεγμένο (Χώρο Πελατών)
    return costTroposArray.find((item) => {
        if (item.xoros.start === selected.xoros.start && item.xoros.end === selected.xoros.end) {
            return item;
        }
    });
};
const findPeriodosCost = async (cost, months) => {
    // 4 ) Να επιστρέφει την (Περίοδο Λειτουργίας)
    let sumCostEdem;
    let sumCostGea;

    if (months === 6) {
        sumCostEdem = makeNumber(cost.edem.cost / 2);
        sumCostGea = makeNumber(cost.gea.cost / 2);
    } else {
        sumCostEdem = makeNumber(cost.edem.cost);
        sumCostGea = makeNumber(cost.gea.cost);
    }

    return { edem: sumCostEdem, gea: sumCostGea }
}


//Εάν η επιλογή είναι Β ) θα πρέπει να πέρνει τα παρακάτω :
const findTheHotelsCost = (selected) => {
    // 1 ) Να πέρνει την επιλεγμένη (Κατηγορία)
    switch (selected.categori.id) {
        case 5:
            return { hotelPerYear: { ...dataCostAll.hotels.year.hotelsForYear[0] }, hotelForSesson: { ...dataCostAll.hotels.months.hotelForMonths[0] } }
        case 6:
            return { hotelPerYear: { ...dataCostAll.hotels.year.hotelsForYear[1] }, hotelForSesson: { ...dataCostAll.hotels.months.hotelForMonths[1] } }
        case 7:
            return { hotelPerYear: { ...dataCostAll.hotels.year.hotelsForYear[2] }, hotelForSesson: { ...dataCostAll.hotels.months.hotelForMonths[2] } }
        default:
            break;
    }
};
const findKinoxristiCost = (selected) => {
    const kinoxristiPerYear = dataCostAll.hotels.year.kinoxristiForYear.find(item => {
        if (item.xoros.start === selected.kinoxristi.start && item.xoros.end === selected.kinoxristi.end) {
            return item;
        }
    });

    const kinoxristiPerMonth = dataCostAll.hotels.months.kinoxristiForMonths.find(item => {
        if (item.xoros.start === selected.kinoxristi.start && item.xoros.end === selected.kinoxristi.end) {
            return item;
        }
    });

    return { kinoxristiPerYear: kinoxristiPerYear, kinoxristiPerMonth: kinoxristiPerMonth }
};
const findHotelPeriodosCost = (cost, months) => {
    if (months === 12) {
        return { hotel: cost.year.hotel, kinoxristi: cost.year.kinoxristi };
    } else if (months === 6) {
        return { hotel: cost.sesson.hotel, kinoxristi: cost.sesson.kinoxristi };
    }
};

const findAllCostForHotel = (rooms, cost) => {
    let edem = cost.hotel.edem.costForRoom * rooms;
    let gea = cost.hotel.gea.costForRoom * rooms;
    let athina = cost.hotel.athina.costForRoom * rooms;

    switch (cost.hotel.catigoria.stars) {
        case 5:
            edem = makeNumber(edem + cost.kinoxristi.edem.stars5_4.cost);
            break;
        case 3:
            edem = makeNumber(edem + cost.kinoxristi.edem.stars3.cost);
            break;
        case 1:
            edem = makeNumber(edem + cost.kinoxristi.edem.stars2_1.cost);
            break;
        default:
            break;
    };

    gea = makeNumber(gea + cost.kinoxristi.gea.cost);

    return { edem: edem, gea: gea, athina: athina }
}

const makeNumber = (num) => {
    return Number(parseFloat(num).toFixed(2));
}