import React from 'react';

//React-Redux
import { connect } from 'react-redux';

//Reselect 
import { createStructuredSelector } from 'reselect';

//Selector
import { selectedItems } from '../../../redux/selections/selections.selectors';
import { selectAllResult } from '../../../redux/result/result.selectors';

//Components
import ExtraRow from '../../checkExtra/extraRow/extraRow.component';
import { BtnPDF } from '../../buttons/btn_pdf/btn.pdf.component';

//Create pdf 
import { generatePDF, createPDF } from '../../pdf/create.pdf';

//style
import { ResultPanelStyle } from './result.panel.style';

function stractureNumber(num) {
    return Number(parseFloat(num).toFixed(2));
}

class ResultPanel extends React.Component {
    state = {
        oroys: false,
        title: 'Αποδέχομαι τους',
        extraLinkTitle: 'όρους χρήσης',
        extraLinkPath: 'https://www.emusic.gr/oroi',
        extraLinkShow: true,
    };

    clickForOroys = () => {
        this.setState({ oroys: !this.state.oroys });
    };

    clickForBtnPDF = () => {
        const { result, selectedItems } = this.props;

        const priceEdemWith = stractureNumber(result.cost.edem * 1.24);
        const priceGeaWith = stractureNumber(result.cost.gea * 1.24);
        const priceAthina = stractureNumber(result.cost.athina * 1.24);

        createPDF(selectedItems.randomNumber, priceEdemWith, priceGeaWith, priceAthina);
    };

    render() {

        return (
            <ResultPanelStyle>
                <ExtraRow
                    showInfo={false}
                    checked={this.state.oroys}
                    title={this.state.title}
                    extraLinkShow={this.state.extraLinkShow}
                    extraLinkTitle={this.state.extraLinkTitle}
                    extraLinkPath={this.state.extraLinkPath}
                    clickCheckBox={this.clickForOroys}
                />
                <BtnPDF enable={this.state.oroys} onClickBtnPDF={this.clickForBtnPDF} />

            </ResultPanelStyle>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    selectedItems: selectedItems,
    result: selectAllResult,
});


export default connect(mapStateToProps)(ResultPanel);