//Redux
import { createStore, applyMiddleware } from 'redux';

// //Persistor
// import { persistStore } from 'redux-persist';

//DevToolsRedux
import { composeWithDevTools } from 'redux-devtools-extension';

//Einai gia na blepoyme sto console tis kinhseis apo thn Redux
import logger from 'redux-logger'

//Einai oi reducer toy project
import rootReducers from '../rootReducers';

//Oles oi extra function gia thn Redux prepei na einai se peribalon 'development'
const middleware = []

//Ean einai se allo periballon den prepei na pernaei middleware
if (process.env.NODE_ENV === 'development') {
    middleware.push(logger);
};

//Ftiaxnoyme to Store
export const store = createStore(rootReducers, composeWithDevTools(applyMiddleware(...middleware)));

// export const persistor = persistStore(store);