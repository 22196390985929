import React from 'react';

//React-Redux
import { connect } from 'react-redux'

//Reselect
import { createStructuredSelector } from 'reselect';
//Selectors
import { selectAllResult } from '../../../redux/result/result.selectors';

//Components
import CheckExtra from '../../checkExtra/check.extra.component';
import StandarCost from '../../standarCost/standar.cost.component';
import { SumCost } from '../../sumCost/sum.cost.component';
//Style
import {
    SecondPanelStyle,

    StandarCostStyle,
    ExtraCostStyle,
    SumCostStyle
} from './secondPanel.style';

class SecondPanel extends React.Component {

    render() {
        const { result } = this.props;

        return (
            <SecondPanelStyle>

                <StandarCostStyle>
                    <StandarCost
                        priceEdem={result.cost.edem}
                        priceGea={result.cost.gea}
                        priceAthina={result.cost.athina}
                        isHotel={result.cost.athina > 0 ? true : false} />
                </StandarCostStyle>

                <ExtraCostStyle>
                    <CheckExtra />
                </ExtraCostStyle>

                <SumCostStyle>
                    <SumCost price={result.sumWith} />
                </SumCostStyle>

            </SecondPanelStyle>
        )
    }
};

const mapStateToProps = createStructuredSelector({
    result: selectAllResult,
})

export default connect(mapStateToProps)(SecondPanel);