import styled from 'styled-components';
import { color_Green_Medium, color_Grey_Ligth } from '../../../styles/colors'

export const StepElementStyle = styled.div`
    position: relative;
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    height: 100%;
`;
export const StepIconStyle = styled.div`
    width: 9.5rem;
    height: 9.5rem;

    svg{
        width: 100%;
        height: 100%;
    }

    @media only screen and (max-width:960px){
        width: 7.5rem;
        height: 7.5rem;
    }

    @media only screen and (max-width:450px){
        width: 5.5rem;
        height: 5.5rem;
    }

    @media only screen and (max-width:350px){
        width: 4.5rem;
        height: 4.5rem;
    }

    
`;

export const StepTitleStyle = styled.h1`
        font-size: 3rem;
	    color:${({ mode }) => mode === 1 ?
        color_Green_Medium
        :
        color_Grey_Ligth};
            
            
    @media only screen and (max-width:650px){
        margin-top: 1rem;
        font-size: 2.5rem;
    }
    
    @media only screen and (max-width:450px){
        font-size: 2rem;
    }
    
    @media only screen and (max-width:350px){
        font-size: 1.7rem;
    }
    @media only screen and (max-width:270px){
        font-size: 1.4rem;
    }
`;