import styled, { css } from 'styled-components';

import { color_Grey_Medium } from '../../styles/colors';

const defaultAlign = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const defaultTextStyle = css`
    font-size:1rem;
    letter-spacing: 1.3px;
`;

const negativeMargin = css`
    &::first-letter{
        margin-right: -2px;
    }
`;

export const PDFPageStyle = styled.div`
    ${defaultAlign};
    padding:0 3rem;

    font-family:'OpenSans-Regular';
    font-style: 'normal';
    width: 45rem;
`;
export const CompanyPanelStyle = styled.div`
    ${defaultAlign};
    h1{
        ${defaultTextStyle}
        letter-spacing: 4px;
        margin:.5rem auto;
        font-size: 1.8rem;
        font-family:'OpenSans-Bold';
        color: ${color_Grey_Medium};
    };
    h3{
        ${defaultTextStyle}
        font-family:'OpenSans-Bold';
        padding-bottom: .2rem;
        border-bottom: 1px solid;
        ${negativeMargin};
    };
`;
export const MonadikosNumberPanel = styled.div`
    ${defaultAlign}
    margin:1.5rem;
    h3{
        font-size:1rem;
        word-spacing: 0;
    }
`;
export const SpecialNumberStyle = styled.h3`
    font-family:'OpenSans-Bold';
    font-size: 1.2rem;
    letter-spacing: 2px;
    margin:0 .5rem;
`;
export const DetailsPanelStyle = styled.div`
`;
export const DetailsTextStyle = styled.div`
    width: 100%;
    ${defaultTextStyle};
    text-align: center;
    font-size: .7rem;
    strong{
        font-family: 'OpenSans-Bold';
    }
    margin-bottom: 1rem;
`;

export const PanalOrganismoyStyle = styled.div`
    ${defaultAlign};
    align-items: flex-start;
    margin-bottom: 1.5rem;
`;
export const OrganismosDetails = styled.div`
    ${defaultAlign}
    align-items: flex-start;

`;
export const OrganismosNameStyle = styled.h3`
    ${defaultTextStyle};
    font-family: 'OpenSans-Bold';
    letter-spacing: 2px;
    font-size: .8rem;
    ${negativeMargin};
`;
export const OrganismosCostStyle = styled.h3`
    ${defaultTextStyle};
    font-family: 'OpenSans-SemiBold';
    font-size: .9rem;
    letter-spacing: 2px;
    margin-top: .3rem;
    margin-bottom: .5rem;
    ${negativeMargin};
`;

export const BankAccoundStyle = styled.h4`
    ${defaultTextStyle};
    margin-bottom: .4rem;
    letter-spacing: 1px;
    font-size: .7rem;
`;

export const BankPanelStyle = styled.div`
    ${defaultAlign};
    
    align-items: flex-start;
    width: 60%;
    margin-bottom: 1rem;
    
    & div{
        font-size:.9rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span{
            font-size:.8rem;
        };
    }
`;

export const BankDetailsStyle = styled.span`
    ${defaultTextStyle}
    font-size: .7rem;
    margin-bottom: .5rem;
`;

export const DetailsAllStyle = styled.div`
    ${defaultTextStyle};
    text-align: center;
    line-height: 1.5;
    letter-spacing: 1px;
    font-family:'OpenSans-Regular';
    font-size:.9rem;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    strong{
        font-family:'OpenSans-Bold';
        padding-bottom: .1rem;
        border-bottom: 1px solid;
    }
`;