import styled from 'styled-components';

//Colors
import { color_Grey_Medium } from '../../styles/colors';

export const CheckExtraPanel = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const CheckExtraTitle = styled.span`
    font-size: 3rem;
    text-decoration: underline;
    color: ${color_Grey_Medium};

    @media only screen and (max-width:600px){
        font-size: 2.8rem;
    }
    @media only screen and (max-width:500px){
        font-size: 2.4rem;
    }
    @media only screen and (max-width:300px){
        font-size: 2.2rem;
    }
`;