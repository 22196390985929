import React from 'react'

//Styles
import {
    InfoExtraStyle,
    InfoTitle
} from './infoBtn.style';

export const InfoExtra = ({ link }) => {

    return (
        <InfoExtraStyle href={link} target="_blank">
            <InfoTitle>i</InfoTitle>
        </InfoExtraStyle>
    )
}