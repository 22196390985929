import { createSelector } from 'reselect';


const stepsState = (state) => state.steps;

export const selectSteps = createSelector(
    [stepsState],
    (steps) => steps.steps
);

export const selectStepNow = createSelector(
    [stepsState],
    (steps) => steps.now
);

export const selectNextBtn = createSelector(
    [stepsState],
    (steps) => steps.nextBtn
);