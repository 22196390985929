import { extraChoiseTypes } from './extra.choise.types';

export const changeEvaradioCheckBox = (value) => ({
    type: extraChoiseTypes.CHANGE_EVARADIO_CHECKED,
    payload: value
});

export const changeNomikesCheckBox = (value) => ({
    type: extraChoiseTypes.CHANGE_NOMIKES_CHECKED,
    payload: value
});