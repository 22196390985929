export const INITIAL = {
    selectors: [
        { id: 0, sortAs: 0, btnNum: "α)", btnTitle: 'Κατηγορία', show: true, showSelections: false },
        { id: 1, sortAs: 1, btnNum: 'β)', btnTitle: 'Σταθ. Τρόπος Μεταδόσης Μουσικής', show: false, showSelections: false },
        { id: 2, sortAs: 2, btnNum: 'γ)', btnTitle: 'Χώρος Πελατών (τ.μ)', show: false, showSelections: false },
        { id: 3, sortAs: 4, btnNum: 'β)', btnTitle: 'Κοινόχρηστοι χώροι', show: false, showSelections: false },
        { id: 4, sortAs: 5, btnNum: 'γ)', btnTitle: 'Διοργανώνετε εκδηλώσεις', show: false, showSelections: false },
        { id: 5, sortAs: 3, btnNum: 'δ)', btnTitle: 'Περίοδος Λειτουργίας', show: false, showSelections: false },
    ],
    catogories: [
        { id: 0, name: 'Cafe Bar' },
        { id: 1, name: 'Restaurant' },
        { id: 2, name: 'Take Away' },
        { id: 3, name: 'Ταβέρνα - Ουζερί' },
        { id: 4, name: 'Παραδοσιακό Καφενείο' },
        { id: 5, name: 'Ξενοδοχεία 5***** ή 4****' },
        { id: 6, name: 'Ξενοδοχεία 3***' },
        { id: 7, name: 'Ξενοδοχεία 2** ή 1*' },
    ],
    domatia: [
    ],
    tropos: [
        { id: 0, name: 'Απλά μέσα (Ραδιόφωνο ή Τηλεόραση)' },
        { id: 1, name: 'Μηχανικά μέσα (Λίστες μουσικής)' },
        { id: 2, name: 'Disc Jockey' },
        { id: 3, name: 'Μηχανική & Ζωντανή μουσική' },
    ],
    xoros: [
    ],
    periodos: [
        { id: 0, name: 'Ετήσια (12 μήνες)', num: 12 },
        { id: 1, name: 'Εποχική (6 μήνες)', num: 6 },
    ],
    kinoxristi: [
        { id: 0, name: 'κοινόχρηστοι χώροι έως 100τμ', start: 1, end: 100 },
        { id: 1, name: 'κοινόχρηστοι χώροι 101-200τμ', start: 101, end: 200 },
        { id: 2, name: 'κοινόχρηστοι χώροι 201-300τμ', start: 201, end: 300 },
        { id: 3, name: 'κοινόχρηστοι χώροι 301-400τμ', start: 301, end: 400 },
        { id: 4, name: 'κοινόχρηστοι χώροι 401τμ και άνω', start: 401, end: 0 },
    ],
    ekdilosis: [
        { id: 0, name: 'Ναι' },
        { id: 1, name: 'Όχι' },
    ],

    selected: {
        categori: {},
        rooms: {},
        tropos: {},
        xoros: {},
        periodos: {},
        kinoxristi: {},
        ekdilosis: {},
        randomNumber: ''
    }
};