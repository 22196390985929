import { selectionsType } from './selection.type';

//Save Categori
export const saveSelectCategori = (categori) => ({
    type: selectionsType.SAVE_SELECT_CATEGORI,
    payload: categori
});

//Save Rooms
export const saveSelectRooms = (rooms) => ({
    type: selectionsType.SAVE_SELECT_ROOMS,
    payload: rooms
});

//Save Tropos
export const saveSelectTropo = (tropos) => ({
    type: selectionsType.SAVE_SELECT_TROPO,
    payload: tropos
});

//Save Xoros
export const saveSelectXoros = (xoros) => ({
    type: selectionsType.SAVE_SELECT_XOROS,
    payload: xoros
});

//Save Periodos
export const saveSelectPeriodos = (periodos) => ({
    type: selectionsType.SAVE_SELECT_PERIODOS,
    payload: periodos
});

//Save Kinoxristi
export const saveSelectKinoxristi = (kinoxristi) => ({
    type: selectionsType.SAVE_SELECT_KINOXRISTI,
    payload: kinoxristi
});

//Save Ekdilosis
export const saveSelectEkdilosis = (ekdilosis) => ({
    type: selectionsType.SAVE_SELECT_EKDILOSIS,
    payload: ekdilosis
});

//Generate random number
export const generateRandomNumber = () => ({
    type: selectionsType.GENARATE_RANDOM_NUMBER,
});

//Clear all selected items
export const clearSelectedItems = () => ({
    type: selectionsType.CLEAR_SELECTED_ITEMS
});

//Show or Hide Selections
export const changeSelectionShow = (value) => ({
    type: selectionsType.CHANGE_SELECTIONS_SHOW,
    payload: value
});

//Show or Hide Selector
export const changeSelectorShow = (selectorID, value = true) => ({
    type: selectionsType.CHANGE_SELECTOR_SHOW,
    payload: selectorID,
    value: value
});