import React from 'react';

//Icons
import { ReactComponent as DownSvg } from '../../../assets/icons/sort_down.svg';

//Style
import {
    BtnDropBoxStyle,
    ComboBtnPanelStyle,
    BtnNumber,
    BtnWithSelections,
    BtnBase,
    BtnTitle,
    BtnSelector,
    SetSelectionStyle
} from './btn.dropbox.style';

const BtnDropBox = ({ btnNum, btnTitle, show = false, showSelections = false, selections = [], clickForSelector, clickForSelectChoise, id,
    roomsTitle, showRooms = false, showRoomSelections = false, roomSelection = [], clickForSelectorRooms, clickForSelectionRooms
}) => {

    return (
        <BtnDropBoxStyle showStep={show}>
            <BtnNumber>{btnNum}</BtnNumber>

            <ComboBtnPanelStyle>
                <BtnWithSelections>
                    <BtnBase onClick={() => clickForSelector(id)}>
                        <BtnTitle>{btnTitle}</BtnTitle>
                        <DownSvg />
                    </BtnBase>

                    <BtnSelector show={showSelections}>
                        {
                            selections !== null && selections.map(choise => {
                                if (choise !== null) {
                                    return (
                                        <SetSelectionStyle key={choise.id} {...choise} onClick={() => clickForSelectChoise(id, choise)}><span>{choise.name}</span></SetSelectionStyle>
                                    )
                                }
                            })
                        }
                    </BtnSelector>
                </BtnWithSelections>

                {showRooms &&
                    <BtnWithSelections onClick={() => clickForSelectorRooms()}>
                        <BtnBase mode='small'>
                            <BtnTitle>{roomsTitle}</BtnTitle>
                            <DownSvg />
                        </BtnBase>

                        <BtnSelector show={showRoomSelections}>
                            {
                                roomSelection.map(room => {
                                    return <SetSelectionStyle key={room.id} onClick={() => clickForSelectionRooms(room)}><span>{room.name}</span></SetSelectionStyle>
                                })
                            }
                        </BtnSelector>
                    </BtnWithSelections>
                }
            </ComboBtnPanelStyle>

        </BtnDropBoxStyle>
    )
}


export default BtnDropBox;