import React from 'react';

//React-Redux
import { connect } from 'react-redux'
//Reselect
import { createStructuredSelector } from 'reselect';
//Selectors
import { selectStepNow, selectNextBtn } from '../../redux/steps/steps.selectors';

//Actions
import { changeStepNext, changeStepPreviews } from '../../redux/steps/steps.actions';

//Components
import { TopTitle } from '../topTitle/top.title.component';
import { ButtonChange } from '../buttons/btn_change_step/btn_change_step.component'

import SelectPanel from '../../components/panel/selectPanel/select.panel.component';
import SecondPanel from '../../components/panel/secondPanel/secondPanel.component';
import ResultPanel from '../../components/panel/resultPanel/result.panel.component';

//Style
import { PanelSTepStyle } from './panel.style';

class PanelForStep extends React.Component {

    render() {
        const { changeStepNext, changeStepPreviews, stepNow, nextBtnShow } = this.props;
        return (
            <PanelSTepStyle>
                {
                    stepNow === 1 ?
                        <React.Fragment>
                            <TopTitle title="Κάντε εύκολα τιμολόγηση με 4 απλά βήματα" step={stepNow} />

                            <SelectPanel />

                            {
                                nextBtnShow && <ButtonChange btnName="Επόμενο" clicked={changeStepNext} />
                            }
                        </React.Fragment>
                        :
                        stepNow === 2 ?
                            <React.Fragment>
                                <TopTitle title="Ανάλυση κόστους" step={stepNow} />

                                <SecondPanel />

                                <ButtonChange btnName="Επόμενο" clicked={changeStepNext} />
                                <ButtonChange btnName="Προηγούμενο" side="Left" clicked={changeStepPreviews} />
                            </React.Fragment>
                            :
                            stepNow === 3 ?
                                <React.Fragment>
                                    <TopTitle title="Για περισσότερες πληροφορίες αλλά και οδηγίες για την πληρωμή κατεβάστε το παρακάτω αρχείο" step={stepNow} />

                                    <ResultPanel />

                                    <ButtonChange btnName="Προηγούμενο" side="Left" clicked={changeStepPreviews} />
                                </React.Fragment>
                                :
                                null
                }
            </PanelSTepStyle>
        )
    }
};


const mapStateToProps = createStructuredSelector({
    stepNow: selectStepNow,
    nextBtnShow: selectNextBtn
});

const mapDispatchToProps = dispatch => ({
    changeStepNext: () => dispatch(changeStepNext()),
    changeStepPreviews: () => dispatch(changeStepPreviews()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelForStep);