import {HomePage} from './pages/home/home.page'



function App() {
  return (
    <div className="App">
      <HomePage />
    </div>
  );
}

export default App;
