import { resultTypes } from './result.types';

// Extra functions
import { calculateSum } from './result.utilities';
const INITIAL = {
    cost: {
        edem: 0,
        gea: 0,
        athina: 0,
        evaradio: 0,
        nomiki: 0
    },
    sum: 0,
    sumWith: 0,
    fpa: 24
};

const resultReducer = (state = INITIAL, action) => {
    switch (action.type) {
        case resultTypes.CHANGE_VALUE_EDEM:
            return { ...state, cost: { ...state.cost, edem: action.payload } };

        case resultTypes.CHANGE_VALUE_GEA:
            return { ...state, cost: { ...state.cost, gea: action.payload } };

        case resultTypes.CHANGE_VALUE_ATHINA:
            return { ...state, cost: { ...state.cost, athina: action.payload } };

        case resultTypes.CHANGE_VALUE_EVARADIO:
            return { ...state, cost: { ...state.cost, evaradio: action.payload } };

        case resultTypes.CHANGE_VALUE_NOMIKI:
            return { ...state, cost: { ...state.cost, nomiki: action.payload } };

        case resultTypes.CHANGE_VALUE_SUM:
            return calculateSum(state);

        default:
            return state;
    }
};

export default resultReducer;