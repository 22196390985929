export const changeStepNext = (num) => {
    if (num < 3) {
        num += 1;
    } else if (num === 3) {
        num = 1
    }
    return num;
}

export const changeStepPreviews = (num) => {
    if (num > 1) {
        num -= 1
    } else if (num === 1) {
        num = 3
    }
    return num
}