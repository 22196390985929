
export const color_White = "#fff";

export const color_Blue_Ligth = "#ADCDFE";
export const color_Blue_Medium = "#7CAFFB";
export const color_Blue_Dark = "#3A89FF";

export const color_Coral_Ligth = "#FBB49A";
export const color_Coral_Medium = "#F86933";
export const color_Coral_Dark = "#C53D09";

export const color_Grey_Ligth = "#E5E5E5";
export const color_Grey_Medium = "#B1B1B1";
export const color_Grey_Dark = "#707070";

export const color_Green_Ligth = "#C7EEA5";
export const color_Green_Medium = "#8CBC61";
export const color_Green_Dark = "#69854F";

export const corol_label_green = "#A7E66E29";

export const color_Black = "#000";