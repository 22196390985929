//Υπολογίζει το συνολικό ποσό
export const calculateSum = (state) => {
    const edem = state.cost.edem;
    const gea = state.cost.gea;
    const athina = state.cost.athina;
    const evaradio = state.cost.evaradio;
    const nomiki = state.cost.nomiki;

    const sum = edem + gea + athina + evaradio + nomiki;

    //Υπολογίζει και προσθετη το φπα στην τιμη
    const sumWith = Number(parseFloat(sum * (1 + (state.fpa / 100))).toFixed(2));

    return { ...state, sum: sum, sumWith: sumWith };
};