import styled from 'styled-components';

import { color_Blue_Medium, color_Grey_Medium } from '../../../styles/colors';

export const ExtraCostStyle = styled.div`
    width:100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:not(:last-child){
        margin-bottom: 1.5rem;
    }
    
    &:first-child{
        margin-top: 1.5rem;
    }
`;

export const ExtraRowDetails = styled.div`
    width:100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const ExtraRowTitle = styled.h3`
    font-size:2.5rem;
    font-weight: 400;
    color:${color_Grey_Medium};

    @media only screen and (max-width:550px){
        font-size: 2rem;
    }
    @media only screen and (max-width:400px){
        font-size: 1.8rem;
    }
    @media only screen and (max-width:330px){
        font-size: 1.6rem;
    }
    @media only screen and (max-width:300px){
        font-size: 1.3rem;
    }
`;

export const EtxraLinkStyle = styled.a`
    font-size: 2.5rem;
    font-weight: 400;
    color: ${color_Blue_Medium};
    margin-left: .7rem;
    @media only screen and (max-width:550px){
        font-size: 2rem;
    }
    @media only screen and (max-width:400px){
        font-size: 1.8rem;
    }
    @media only screen and (max-width:330px){
        font-size: 1.6rem;
    }
    @media only screen and (max-width:300px){
        font-size: 1.3rem;
    }
`;