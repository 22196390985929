import styled from 'styled-components';

export const HomeComponent = styled.div`
    position: relative;
    
    width:120rem;
    height: auto;
    
    margin:0 auto;
    padding:2rem;
    
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;

    @media only screen and (max-width:960px){
        padding: 2rem 0;
        width: 100%;
        height:auto;
    }



`;