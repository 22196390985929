import React from 'react';

//Components
import { LabelCost } from '../labelCost/label.cost.component';

//Style
import { SumCostStyle, SpanFPA } from './sum.cost.style';

export const SumCost = ({ price = 0 }) => {

    return (
        <SumCostStyle>
            <LabelCost description='Συνολικό Κόστος' price={price} mode='green' />
            <SpanFPA>( Η τιμή συμπεριλαμβάνει ΦΠΑ 24% )</SpanFPA>
        </SumCostStyle>
    )
}