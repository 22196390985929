import { resultTypes } from './result.types';

export const changeValueEdem = (price) => ({
    type: resultTypes.CHANGE_VALUE_EDEM,
    payload: price
});

export const changeValueGea = (price) => ({
    type: resultTypes.CHANGE_VALUE_GEA,
    payload: price
});

export const changeValueAthina = (price) => ({
    type: resultTypes.CHANGE_VALUE_ATHINA,
    payload: price
});

export const changeValueEvaradio = (price) => ({
    type: resultTypes.CHANGE_VALUE_EVARADIO,
    payload: price
});

export const changeValueNomiki = (price) => ({
    type: resultTypes.CHANGE_VALUE_NOMIKI,
    payload: price
});

export const changeValueSum = () => ({
    type: resultTypes.CHANGE_VALUE_SUM
});