import React from 'react';

//Style for boxs
import {
    StepElementStyle,
    StepIconStyle,
    StepTitleStyle
} from './step.style';

//Icon for step
import { ReactComponent as StepEnableSVG } from '../../../assets/icons/step_enable.svg';
import { ReactComponent as StepDiasbleSVG } from '../../../assets/icons/step_disable.svg';

class Step extends React.Component {

    render() {

        const { name, step, stepNow } = this.props;

        return (
            <StepElementStyle classname='step'>

                {step === stepNow ?
                    <React.Fragment>
                        <StepIconStyle>
                            <StepEnableSVG />
                        </StepIconStyle>
                        <StepTitleStyle classname='title' mode={1}>{name}</StepTitleStyle>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <StepIconStyle>
                            <StepDiasbleSVG />
                        </StepIconStyle>
                        <StepTitleStyle classname='title' mode={0}>{name}</StepTitleStyle>
                    </React.Fragment>
                }

            </StepElementStyle>
        )
    }

};

export default Step;